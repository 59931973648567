<template>
  <div class="entry-list-container">

      <div class="mt-2 d-flex flex-column">
        <button class="btn btn-success mx-3"
          @click="$router.push({ name: 'entry', params: { id: 'new' } })">
          <i class="fa fa-plus-circle"></i>
          Nueva entrada
        </button>
      </div>

      <div class="entry-scrollarea">
          <Entry
            v-for="entry in allEntries"
            :key="entry.id"
            :entry="entry"
          />
      </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    Entry: defineAsyncComponent(() => import('./Entry.vue'))
  },
  computed: {
    ...mapGetters('images', ['getEntries']),
    allEntries() {
      return this.getEntries()
    }
  }
}
</script>



<style lang="scss" scoped>
.entry-list-container{
    border-right: 1px solid #2c3e50;
    height: calc( 100vh - 56px );
}

.entry-scrollarea{
    height: calc( 100vh - 110px );
    overflow: scroll;
}
</style>
